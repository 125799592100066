.locker-dialog {
  .label {
    width: 100%;
    margin-left: 32px !important;
  }

  .bin-container {
    border: 1px solid #0000001f;
    .status-item {
      width: fit-content;
      padding: 0 8px;

      &.active {
        background-color: #25a09a;
        color: white;
      }

      &.inactive {
        border-color: #291f6c;
        border: 1px solid;
      }
    }
  }

  .actions {
    align-items: baseline;
  }

  .button {
    width: 175px;
    height: 55px;
  }
}
