.notification-item-conteiner {
  margin-bottom: 8px;

  .statusContainer {
    width: 100px;
    margin-bottom: 8px;
  }

  .active {
    background-color: #25a09a;
    color: white;
  }

  .expired {
    border: 1px solid #291f6c;
  }

  .attention {
    background-color: #fcca46;
  }

  .future {
    background-color: #291f6c;
  }

  &:first-child {
    margin-top: 8px;
  }

  .item {
    margin-right: 16px;
  }

  .small {
    flex: 1;
  }
  .large {
    flex: 1.5;
  }

  .button {
    height: 55px;
  }
  .text-item-label {
    font-weight: normal;
  }
}
