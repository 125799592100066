.notification-settings-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .label {
    margin: 0px 2px;
  }
  .disabled {
    color: #cecece;
  }
}
