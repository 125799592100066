.service-order-item-conteiner {
    margin-bottom: 8px;

    &:first-child {
        margin-top: 8px;
    }

    .status-item {
        width: fit-content;
        padding: 0 8px;

        &.active {
            background-color: #25A09A;
            color: white;
        }

        &.inactive {
            border-color:#291F6C;
            border: 1px solid,
        }

        &.attention {
            background-color: #FCCA46;
        }
    }

    .item {
        margin-right: 16px;
    }

    .small {
        flex: 1;
    }
    .large {
        flex: 2;
    }

    .description-label {
        font-family: "Avenir-Black";
        font-size: 14;
    }
}