.inner-container {
  flex: 1;
  background-color: white;
  align-items: flex-start;
  padding: 16px 32px;
  max-width: calc(370px - 32px);
  max-height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;

  .label-spacing {
    padding: 0px 8px;
  }

  .language-container {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 24px;
    width: 100%;

    button {
      font-family: Avenir-Black !important;
      text-decoration: underline;
    }
  }

  .user-container {
    display: flex;
    flex: 1;
    align-items: flex-start;
  }
  .button {
    width: 180px;
    height: 60px;
    margin: 0 !important;
    &:first-child {
      margin-bottom: 4px !important;
    }
  }

  .second-container {
    margin-top: 16px;
  }

  .language-label {
    color: #291f6c;
    font-size: 16px;
    font-family: Avenir-Black;
    text-decoration: underline;
  }

  .contact-provider-label {
    margin-top: 8px !important;
    margin-bottom: 24px !important;
  }
}
