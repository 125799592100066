.location-dialog {
  .label {
    width: 100%;
    margin-left: 32px !important;
  }

  .actions {
    align-items: baseline;
  }

  .button {
    width: 175px;
    height: 55px;
  }

  .left-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .right-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .top-input {
    border-bottom-width: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .bottom-input {
    border-top-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .helper-text {
    margin-left: 14px;
  }

  .country-input {
    margin-right: 16px;
  }

  .image-button {
    width: 100%;
    height: 100%;
    max-height: 190px;
    border: 1px solid #0000001f;
    border-radius: 4px;
    padding: 0;

    .image {
      width: 100%;
      height: 100%;
    }
  }
}
