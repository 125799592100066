.outlined-container {
  position: relative;
  border: 1px solid #291f6c4d;
  border-radius: 4px;
  width: 100%;

  .disabled-container {
    border-color: #cecece4d;
  }

  .children-container {
    padding: 16px 8px;
  }
  .legend {
    position: absolute;
    text-transform: uppercase;
    top: -12px;
    left: 10px;
    background-color: white;
  }
  .disabled {
    color: #cecece;
  }
}
