.location-item-conteiner {
  margin-bottom: 8px;

  &:first-child {
    margin-top: 8px;
  }

  .item {
    margin-right: 16px;
  }

  .small {
    flex: 1;
  }
  .large {
    flex: 2;
    overflow: hidden;
  }

  .description-label {
    font-family: "Avenir-Black";
    font-size: 14;
  }
  .button {
    height: 55px;
    &:last-child {
      margin-left: 8px;
    }
  }
}
