.filter-bar-container {
  display: flex;
  align-items: baseline;
  padding: 16px 0;

  .filter-container {
    display: flex;
    flex: 1;
    height: 12px;
    align-items: center;
    padding: 16px 24px;
    border: 1px solid #0000001f;

    .label {
      font-weight: normal;
    }

    .chip {
      margin-left: 8px;
    }
  }
  .button {
    height: 58px;
    margin-left: 16px;
    min-width: 180px;
    margin-bottom: 0px !important;
  }
}
