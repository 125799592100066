@font-face {
	font-family: "Helvetica-Regular";
	src: url("../fonts/Helvetica-Regular.ttf")
		format("truetype");
}

@font-face {
	font-family: "Avenir-Black";
	src: url("../fonts/Metropolis-Black.otf")
		format("opentype");
}

@font-face {
	font-family: "Avenir-Medium";
	src: url("../fonts/Metropolis-Medium.otf") format("opentype");
}

@font-face {
	font-family: "Avenir-Regular";
	src: url("../fonts/Metropolis-Regular.otf") format("opentype");
}

@font-face {
	font-family: "Avenir-Book";
	src: url("../fonts/Avenir-Book.ttf") format("truetype");
}

html {
	height: 100%;
}

body {
	font-family: "Avenir-Medium";
	height: 100%;
	margin: 0;
}

#root, .root {
	height: 100%;
}

a {
	text-decoration: none;
	color: inherit;
	cursor: pointer;
}
