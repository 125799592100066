.top-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    background-color: #291F6C;
    // 60px should be the height. We are decreasing it with 32px which is the padding: 2x16px
    height: calc(60px - 32px);
    .item {
        font-family: "Avenir-Book";
        color: #FFFFFFDE;
        margin: 0 8px;
    }
    .selected {
        font-family: "Avenir-Black";
        color: #50BCBA;
    }
}

